import React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import '../style/mogdb.scoped.scss'

const advantageList = [{
  title: <FormattedMessage id="index.independentHASuite" />,
  description: <FormattedMessage id="index.independentHASuiteDetail" />
}, {
  title: <FormattedMessage id="index.richMonitoringItems" />,
  description: <FormattedMessage id="index.richMonitoringItemsDetail" />
}, {
  title: <FormattedMessage id="index.exhaustion" />,
  description: <FormattedMessage id="index.exhaustionDetail" />
}, {
  title: <FormattedMessage id="index.multithreading" />,
  description: <FormattedMessage id="index.multithreadingDetail" />
}, {
  title: <FormattedMessage id="index.multipleStorageEngines" />,
  description: <FormattedMessage id="index.multipleStorageEnginesDetail" />
}, {
  title: <FormattedMessage id="index.WALParallelReplay" />,
  description: <FormattedMessage id="index.WALParallelReplayDetail" />
}]
const functionListLeft = [{
  title: <FormattedMessage id="index.APIs" />,
  description: <FormattedMessage id="index.apisDetail" />
}, {
  title: <FormattedMessage id="index.standardSQLs" />,
  description: <FormattedMessage id="index.standardSQLsDetail" />
}, {
  title: <FormattedMessage id="index.databaseStorageManagement" />,
  description: <FormattedMessage id="index.databaseStorageManagementDetail" />
}]
const functionListRight = [{
  title: <FormattedMessage id="index.managementTools" />,
  description: <FormattedMessage id="index.managementToolsTitle" />
}, {
  title: <FormattedMessage id="index.SecurityManagement" />,
  description: <FormattedMessage id="index.securityManagementDetail" />
}, {
  title: <FormattedMessage id="index.deployment" />,
  description: <FormattedMessage id="index.deploymentDetail" />
}]
const functionList = [
  {
    title: <FormattedMessage id="index.APIs" />,
    description: <FormattedMessage id="index.apisDetail" />,
    titleRight: <FormattedMessage id="index.managementTools" />,
    descriptionRight: <FormattedMessage id="index.managementToolsTitle" />
  }, {
    title: <FormattedMessage id="index.standardSQLs" />,
    description: <FormattedMessage id="index.standardSQLsDetail" />,
    titleRight: <FormattedMessage id="index.SecurityManagement" />,
    descriptionRight: <FormattedMessage id="index.securityManagementDetail" />
  }, {
    title: <FormattedMessage id="index.databaseStorageManagement" />,
    description: <FormattedMessage id="index.databaseStorageManagementDetail" />,
    titleRight: <FormattedMessage id="index.deployment" />,
    descriptionRight: <FormattedMessage id="index.deploymentDetail" />
  }
]

const IndexPage = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  return(
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={false}>
      <Seo title="MogDB: Enterprise-Ready openGauss Powered by ENMOTECH"/>
      <div className="home-container">
        <div className="banner-box">
          <div className="product-banner-box">
            <i className="mogdb"></i>
            <div className="product-banner-desc">
              <p><FormattedMessage id="index.banner" /></p>
              <p className="bannerFeature"><FormattedMessage id="index.bannerFeature" /></p>
            </div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/mogdb/`} className="btn download"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/`} target="_blank" className="btn searchDoc"><FormattedMessage id="index.docs" /></a>
              <Link to={`${pagePrefix}/featureComparison/`} className="btn version"><FormattedMessage id="index.versionComparse" /></Link>
            </div>
          </div>
        </div>
        <div className="product-box">
          <div className="core-features">
            <div className="highSecurity">
                <div className="product-icon product-icon-2"></div>
                <div className="right">
                  <div className="feature-title"><FormattedMessage id="index.highSecurityTtile" /></div>
                  <div className="feature-des">
                    <div><i></i><span><FormattedMessage id="index.highSecurity1" /></span></div>
                    <div><i></i><span><FormattedMessage id="index.highSecurity2" /></span></div>
                    <div><i></i><span><FormattedMessage id="index.highSecurity3" /></span></div>
                    <div><i></i><span><FormattedMessage id="index.highSecurity4" /></span></div>
                  </div>
                </div>
            </div>
            <div className="highPerformance">
              <div className="product-icon product-icon-1"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="index.highPerformanceTitle" /></div>
                <div className="feature-des">
                  <div><i></i><span><FormattedMessage id="index.highPerformance1" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.highPerformance2" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.highPerformance3" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.highPerformance4" /></span></div>
                  {pageLang === 'zh' && <div><i></i><span><FormattedMessage id="index.highPerformance5" /></span></div>}
                </div>
              </div>
            </div>
            <div className="highCompatibility">
              <div className="product-icon product-icon-4"></div>
              <div className="right">
                <div className="feature-title"><FormattedMessage id="index.highCompatibilityTitle" /></div>
                <div className="feature-des">
                  <div><i></i><span><FormattedMessage id="index.highCompatibility1" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.highCompatibility2" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.highCompatibility3" /></span></div>
                </div>
              </div>
            </div>
            <div className="easyToMaintain">
              <div className="product-icon product-icon-3"></div>              
              <div className="right">
                <div className="feature-title"><FormattedMessage id="index.easyToMaintainTitle" /></div>
                <div className="feature-des">
                  <div><i></i><span><FormattedMessage id="index.easyToMaintain1" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.easyToMaintain2" /></span></div>
                  <div><i></i><span><FormattedMessage id="index.easyToMaintain3" /></span></div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-content">
            <div className="head">
              <FormattedMessage id="index.bannerTitle" />
            </div>
            {pageLang === 'zh' &&
              <div className="banner-text">
                MogDB 是 En<span className="mark">Mo</span>tech <span className="mark">o</span>pen<span className="mark">G</span>auss <span className="mark">D</span>ata<span className="mark">B</span>ase Enterprise Edition 的缩写，是云和恩墨基于 openGauss 内核进行研发，推出的一款极致易用的企业级关系型数据库。MogDB具备金融级高可用和全密态计算的极致安全、面向多核处理器的极致性能、AI自诊断调优的极致智能能力，能够满足从核心交易到复杂计算的企业级业务需求。
              </div>
            }
            {pageLang === 'en' &&
              <div className="banner-text">
                <FormattedMessage id="index.bannerDetail" />
              </div>
            }
            <div className="banner-text"><FormattedMessage id="index.bannerDetailSec" /></div>
          </div>
          <div className="product-content">
            <div className="application-scenarios">
              <div className="product-title">
                <FormattedMessage id="index.applicationScenarios" />
              </div>
              <div className="scene-box">
                <div className="scene scene-1">
                  <div className="title"><FormattedMessage id="index.transactionApplications" /></div>
                  <div><FormattedMessage id="index.transactionApplicationsDetail" /></div>
                </div>
                <div className="scene scene-2">
                  <div className="title"><FormattedMessage id="index.IoTData" /></div>
                  <div><FormattedMessage id="index.IoTDataDetail" /></div>
                </div>
              </div>
            </div>
            <div className="baic-function">
              <div className="head">
                <FormattedMessage id="index.basicFunctions" />
                <span>Basic Functions</span>
              </div>
              <div className="function-box advantage-box">
                <div className="icon-bg-mobile"></div>
                <div className="content-mobile">
                  {functionList.map((item, idx) => (
                    <div key={idx}>
                      <div className="left">
                        <div className="feature-title">{item.title}</div>
                        <div className="feature-des">{item.description}</div>
                      </div>
                      <div className="right">
                        <div className="feature-title">{item.titleRight}</div>
                        <div className="feature-des">{item.descriptionRight}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="content">
                  <div className="left">
                    {functionListLeft.map((item, idx) => (
                      <div key={idx}>
                        <div className="feature-title">{item.title}</div>
                        <div className="feature-des">{item.description}</div>
                      </div>
                    ))}
                  </div>
                  <div className="icon-bg"></div>
                  <div className="right">
                    {functionListRight.map((item, idx) => (
                      <div key={idx}>
                        <div className="feature-title">{item.title}</div>
                        <div className="feature-des">{item.description}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="product-Features">
              <div className="head">
                <FormattedMessage id="index.product" />
                <span>Product Advantages And Highlights</span>
              </div>
              <div className="feature-box advantage-box">
                {advantageList.map((item, idx) => (
                  <div key={idx} className="advantage">
                    <div>
                      <div className={`product-icon product-advantage-icon-${idx}`}></div>
                      <div className="feature-title">{item.title}</div>
                    </div>
                    <div className="feature-des">{item.description}</div>
                  </div>
                ))}
              </div>
              <div className="feature-box media-advantage-box">
                {advantageList.map((item, idx) => (
                  <div key={idx} className="advantage">
                    <div className={`product-icon product-advantage-icon-${idx}`}></div>
                    <div>
                      <div className="feature-title">{item.title}</div>
                      <div className="feature-des">{item.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="experience">
          <div>
            <div className="e-title"><FormattedMessage id="index.experience" /></div>
            <div className="e-content"><FormattedMessage id="index.experienceContent" /></div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/mogdb/`} className="btn download"><FormattedMessage id="index.useNow" /></Link>
              <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)}
export default IndexPage
